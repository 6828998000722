import React, { useEffect } from 'react';
import Map from './Map'
import {Company} from './Company'
import Stack from 'react-bootstrap/Stack'
import { motion } from "framer-motion";

function Cohort() {
  // Animation Variants
  const titleVariants = {
    offscreen: {
      x: 1000
    },
    onscreen: {
      x: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1
      }
    }
  };
  const cohortVariants = {
    offscreen: {
      opacity: 0, 
      scale: 2
    },
    onscreen: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1
      }
    }
  };

  // Hooks
  useEffect(() => {
    const quoteHeadshots = importAll(require.context('../assets/images/team-quotes', false, /\.(png|jpe?g|svg)$/));

    quoteHeadshots.forEach((image) => {
			const newImage = document.createElement("img");
      newImage.src = image;
      window[image] = newImage;
		})
  });
  
  // Helper Functions
  function importAll(r) {
		return r.keys().map(r);
	}

  // Render Component
  return (
    <div className="cohort" >
      <div className='flex-container' id="cohort" >
        <div className='flex-child' id='intro-right'>
          <motion.div className="cohort-text" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
            <motion.div style={{border: 0}} variants={titleVariants}>
              <div className="section-subtitle-text">We accept companies from all over the world.</div>
              <div className="section-title-text">Meet Our Altus<br/>Cohort.</div>
            </motion.div>
          </motion.div>
          <div className='map'>
            <Map/>
            <h5><b>2025 COHORT</b></h5>
          </div>
        </div>
      </div>
      <motion.div
        className="card-container"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.3 }}
      >
        <motion.div className="card" style={{border: 0}} variants={cohortVariants}>
          <div className="scroll-through" id="company-cards">
              <Stack gap={2}>
                <Company name='CorpusAnalytiX'/>
                <Company name='Odynn'/>
              </Stack>
              <Stack gap={2}>
                <Company name='Cosmos'/>
                <Company name='PluggableAI'/>
              </Stack>
              <Stack gap={2}>
                <Company name='RIZZARR'/>
              </Stack>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Cohort;