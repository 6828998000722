export const verticals = ["AI", "EdTech", "Energy", "FinTech", "Healthcare", "Insurance", "IT", "Metaverse", "Real Estate", "SaaS", "Security", "Sports", "Infra", "Research"];
export const verticalsMap = new Map([
  ["Sports", '#74D3F1'],
  ["Healthcare", '#F174B0'],
  ["Energy", '#F1EC74'],
  ["SaaS", "#74F188"],
  ["Security", "#7E74F1"],
  ["Insurance", "#F1A874"],
  ["AI", "#74F1E2"],
  ["Real Estate", "#F17474"],
  ["FinTech", "#F174DD"],
  ["Metaverse", "#74A6F1"],
  ["Payments", "#d80fdb"],
  ["IT", "#F2C879"],
  ["Infra", "#A0B392"],
  ["Research", "#69f062"],
  ["EdTech", "#9c8c40"]
]);
export const applicationLink = "https://www.f6s.com/wharton-cypher-accelerator-2025/apply";
export const interestLink = "https://forms.gle/LuNA86cb6jL7gCbQ9";
export const jobLink = "https://docs.google.com/forms/d/e/1FAIpQLSdApY_Rg3EOiYX3qL0lnyeetJ21vSxHPbP8_bO1Lwr4A9w5Og/viewform";
export const gigaLink = "https://giga.global/";