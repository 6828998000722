import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import $ from 'jquery';
import { motion, AnimatePresence } from 'framer-motion';
import Stack from 'react-bootstrap/Stack';
import Navibar from '../components/Navibar';
import Footer from '../components/Footer';
import Company from '../components/Companies';
import PageSlider from "../components/Page-Slider"
import CypherLoading from '../components/CypherLoading'
import Companies from '../assets/companies.json'
import { verticalsMap, verticals } from '../utilities/constants';
import { MdSearchOff } from 'react-icons/md';

function CompaniesPage() {
  // Storage Arrays
  const AllCompanies = [];

  // Cohorts Checkbox Arrays
  var cohortNameLists = [];
  const cohorts = ["Genesis", "Fortis", "Dominion", "Altus"]
  const cohortsSet = new Set(cohorts);
  const cohortCheckboxes = [];

  // Verticals Checkbox Arrays
  var verticalsNameLists = [];
  const verticalsCheckboxes = [];

  // State Hooks
  const [checkboxArrayState, setCheckboxArrayState] = useState([])
  const [liveArray, setLiveArray] = useState([AllCompanies])
  const [loadingState, setLoadingState] = useState(false)

  // Effect Hooks
  useEffect(() => {
		updateCompaniesPageState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkboxArrayState]);
  useEffect(() => {
		// Preloading Profile Images
		const companyImages = importAll(require.context('../assets/images/companylogosblue/', false, /\.(png|jpe?g|svg)$/));
		
		companyImages.forEach((image) => {
			const newImage = document.createElement("img");
      newImage.src = image;
      window[image] = newImage;
		});
	});
  useLayoutEffect(() => {
		document.body.classList.add("background-static")
		setLoadingState(true)
		setTimeout(() => {
			setLoadingState(false)
			document.body.classList.remove("background-static")
			$('body,html').animate({ scrollTop: 0, }, 0);
		}, 5000)
	}, []);

  // Functions
  function importAll(r) {
		return r.keys().map(r);
	}
  function loadArrays() {
    verticalsNameLists = new Array(verticals.length);
    for (var i = 0; i < verticalsNameLists.length; i++) {
      verticalsNameLists[i] = [];
    }

    cohortNameLists = new Array(cohorts.length);
    for (var i = 0; i < cohortNameLists.length; i++) {
      cohortNameLists[i] = [];
    }
  }
  function getCheckboxValues() {
    var checkboxes = document.getElementsByClassName("expertise-pill-checkbox");
    var tempCheckboxArray = [];
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        tempCheckboxArray.push(checkboxes[i].value);
      }
    }

    return tempCheckboxArray;
  }
  function updateCompaniesPageState() {
    var checkboxFilteredNames = [];
    var newLiveArray = [];

    for (var topic of checkboxArrayState) {
      if (cohortsSet.has(topic)) {
        checkboxFilteredNames.push(...cohortNameLists[cohorts.indexOf(topic)]);
      } else {
        checkboxFilteredNames.push(...verticalsNameLists[verticals.indexOf(topic)]);
      }
		}
		checkboxFilteredNames = [...new Set(checkboxFilteredNames)];

    if (checkboxArrayState.length === 0) {
      setLiveArray(AllCompanies);
    } else {
      newLiveArray = AllCompanies.filter(element => checkboxFilteredNames.includes(element.key));
      setLiveArray(newLiveArray);
    }
  }
  function returnOutput() {
    if (!liveArray.length) {
      return (
        <div className="no-search-output">
          <hr className="top-bar"/>
          <MdSearchOff/>
          <div className="no-search-output-text">No search results found.</div>
          <hr className="bottom-bar"/>
        </div>
      )
    } else {
      return (
        <div className="search-output">
          <div className="companies-array">{liveArray}</div>
        </div>
      )
    }
  }

  // Executed Code
  loadArrays();
  for (var key in Companies) {
    AllCompanies.push(<Company name={key} key={key} data={Companies[key]}></Company>)
    for (var topic of Companies[key].verticals) {
      verticalsNameLists[verticals.indexOf(topic)].push(key);
    }
    cohortNameLists[cohorts.indexOf(Companies[key].cohort)].push(key);
  }
  for (var topic of verticals) {
    verticalsCheckboxes.push(<span className="expertise-pill" key={topic}>
      <input className="expertise-pill-checkbox" type="checkbox" id={topic} value={topic} onChange={(evt) => {setCheckboxArrayState(getCheckboxValues)}}/>
      <label className="expertise-pill-text" style={{backgroundColor: verticalsMap.get(topic)}} htmlFor={topic}>{topic}</label>
    </span>)
  }
  for (var cohort of cohorts) {
    cohortCheckboxes.push(<span className="expertise-pill" key={cohort}>
      <input className="expertise-pill-checkbox" type="checkbox" id={cohort} value={cohort} onChange={(evt) => {setCheckboxArrayState(getCheckboxValues)}}/>
      <label className="expertise-pill-text" style={{backgroundColor: "white", color: "black"}} htmlFor={cohort}>{cohort}</label>
    </span>)
  }

  // Render Method
  return (
    <div className="companies-page">
      <AnimatePresence style={{width: '100vw'}}>
        {loadingState &&
          <motion.div className="loading-page" initial={{opacity: 1}} exit={{opacity: 0, transition: {duration: 0.5, type: "ease"}}}>
            <div className="cypher-loading">
              <CypherLoading/>
            </div>
          </motion.div>
        }
      </AnimatePresence>
      <PageSlider />
      <Navibar secondaryPage={true}/>
      <div className="secondary-page-wrapper">
        <div className='secondary-page-header'>
          <h1 className='section-title-text'>Our Companies.</h1>
        </div>
        <div className="interact-panel">
          <div className="panel-description">Explore all of our accelerated companies.</div>
          <Stack direction="horizontal">{cohortCheckboxes}</Stack>
          <hr/>
          <Stack direction="horizontal">{verticalsCheckboxes}</Stack>
        </div>
        {returnOutput()}
      </div>
      <Footer/>
    </div>
  )
}

export default CompaniesPage;